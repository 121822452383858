var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-main",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-container",
        { staticClass: "p-0 px-2" },
        [
          _c(
            "v-row",
            { staticClass: "d-flex justify-content-center" },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", sm: "10", md: "7", lg: "5" } },
                [
                  _c(
                    "div",
                    { staticClass: "bg-white border p-5" },
                    [
                      _vm.phase == "request"
                        ? _c(
                            "div",
                            [
                              _c("h1", { staticClass: "display-3" }, [
                                _vm._v(_vm._s(_vm.$t("Login"))),
                              ]),
                              _c("hr"),
                              _vm._v(" " + _vm._s(_vm.$t("EmailAdress")) + " "),
                              _c("v-text-field", {
                                ref: "email",
                                staticClass: "mb-3",
                                attrs: {
                                  id: "email",
                                  autocomplete: "username",
                                  error: _vm.errors.email,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  "append-icon": "email",
                                  placeholder: _vm.$t("EmailAdress"),
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.OnDefaultEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.model.email,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "email", $$v)
                                  },
                                  expression: "model.email",
                                },
                              }),
                              _vm._v(" " + _vm._s(_vm.$t("Password")) + " "),
                              _c("v-text-field", {
                                ref: "password",
                                staticClass: "mb-3",
                                attrs: {
                                  id: "password",
                                  autocomplete: "new-password",
                                  error: _vm.errors.password,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  "append-icon": _vm.showpassword
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showpassword ? "text" : "password",
                                  placeholder: _vm.$t("Password"),
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showpassword = !_vm.showpassword
                                  },
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.OnDefaultEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.model.password,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "password", $$v)
                                  },
                                  expression: "model.password",
                                },
                              }),
                              _c(
                                "v-row",
                                {
                                  staticClass:
                                    "my-3 d-flex justify-content-center align-items-center",
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "mr-0 pr-0",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _vm.captchaUrl.length == 0
                                        ? _c("v-skeleton-loader", {
                                            attrs: {
                                              "max-width": "240",
                                              "max-height": "70",
                                              "min-width": "240",
                                              "min-height": "70",
                                              type: "image",
                                            },
                                          })
                                        : _c("img", {
                                            style: {
                                              filter:
                                                "blur(" +
                                                (_vm.captchaBlur
                                                  ? "5px"
                                                  : "0px") +
                                                ")",
                                            },
                                            attrs: {
                                              alt: "Captcha",
                                              src: _vm.captchaUrl,
                                              width: "240",
                                              height: "70",
                                            },
                                          }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            depressed: "",
                                            icon: "",
                                            color: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.ReloadCaptcha()
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.captchaBlur
                                                  ? "mdi-eye"
                                                  : "cached"
                                              )
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("SecurityCode")) + " "
                              ),
                              _c("v-text-field", {
                                staticClass: "mb-5",
                                attrs: {
                                  error: _vm.errors.captcha,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  "append-icon": "mdi-shield-check",
                                  placeholder: _vm.$t("SecurityCode"),
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.OnDefaultEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.model.captcha,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "captcha", $$v)
                                  },
                                  expression: "model.captcha",
                                },
                              }),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("h1", { staticClass: "display-3" }, [
                                _vm._v(_vm._s(_vm.$t("LoginVerification"))),
                              ]),
                              _c("hr"),
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    text: "",
                                    color: "primary",
                                    icon: "mdi-information-outline",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "SMSSentToYourPhoneContactDepartmentIfYouThinkItsWrong",
                                        {
                                          0: _vm.phonecode,
                                          1: _vm.maskedphonenumber,
                                        }
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-alert",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.timerremaining == 0,
                                      expression: "timerremaining == 0",
                                    },
                                  ],
                                  attrs: {
                                    text: "",
                                    color: "red",
                                    icon: "mdi-clock-outline",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("CodeExpiredSendNewCodeRequest")
                                    )
                                  ),
                                ]
                              ),
                              _c(
                                "v-alert",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.timerremaining > 0,
                                      expression: "timerremaining > 0",
                                    },
                                  ],
                                  attrs: {
                                    text: "",
                                    color: "primary",
                                    icon: "mdi-clock-outline",
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("CodeIsValidForXSecond")) +
                                      ": " +
                                      _vm._s(_vm.timerremaining) +
                                      " " +
                                      _vm._s(_vm.$t("Second"))
                                  ),
                                ]
                              ),
                              _vm._v(
                                " " + _vm._s(_vm.$t("VerificationCode")) + " "
                              ),
                              _c("v-text-field", {
                                staticClass: "mb-3",
                                attrs: {
                                  error: _vm.errors.verificationcode,
                                  disabled: _vm.timerremaining == 0,
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  color: "primary",
                                  "append-icon": "dialpad",
                                  placeholder: _vm.$t("VerificationCode"),
                                },
                                on: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    )
                                      return null
                                    return _vm.OnDefaultEnter.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.model.verificationcode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.model, "verificationcode", $$v)
                                  },
                                  expression: "model.verificationcode",
                                },
                              }),
                            ],
                            1
                          ),
                      _vm.phase == "request"
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                disabled: _vm.LoginRequestButtonDisableState,
                                depressed: "",
                                block: "",
                                color: "primary white--text",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.Login_Request()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("Login")))]
                          )
                        : _c(
                            "div",
                            [
                              _vm.timerremaining == 0
                                ? _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.processing,
                                        depressed: "",
                                        block: "",
                                        color: "primary white--text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Login_Send_OTP()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Resend")))]
                                  )
                                : _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        disabled: _vm.processing,
                                        depressed: "",
                                        block: "",
                                        color: "primary white--text",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.Login_Verification()
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.$t("Verify")))]
                                  ),
                            ],
                            1
                          ),
                      _c("hr"),
                      _c(
                        "p",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/Account/Register" } },
                            [_vm._v(_vm._s(_vm.$t("Register")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/Account/Forgot" } },
                            [_vm._v(_vm._s(_vm.$t("ForgotPassword")))]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "p",
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/Account/Application" } },
                            [_vm._v(_vm._s(_vm.$t("BecomeASupplier")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.processing
                    ? _c("v-progress-linear", {
                        attrs: { color: "primary", indeterminate: "" },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "390" },
          model: {
            value: _vm.showErrorPopup,
            callback: function ($$v) {
              _vm.showErrorPopup = $$v
            },
            expression: "showErrorPopup",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "mx-auto pt-7 pb-2" },
            [
              _c("alertIcon", { attrs: { icon: "warning" } }),
              _c("v-card-title", [
                _c("p", { staticClass: "headline" }, [
                  _vm._v(_vm._s(_vm.$t("Warning"))),
                ]),
              ]),
              _c(
                "v-card-text",
                _vm._l(_vm.errorMessages, function (message, i) {
                  return _c("p", { key: i, staticClass: "mb-0" }, [
                    _vm._v(_vm._s(message)),
                  ])
                }),
                0
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", color: "warning", block: "" },
                      on: {
                        click: function ($event) {
                          return _vm.ClearErrors(true)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("Ok")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "" },
          model: {
            value: _vm.processing,
            callback: function ($$v) {
              _vm.processing = $$v
            },
            expression: "processing",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "py-5", attrs: { color: "transparent", dark: "" } },
            [
              _c(
                "v-card-text",
                { staticClass: "text-center py-5" },
                [
                  _c("v-progress-circular", {
                    attrs: { indeterminate: "", size: 50, color: "white" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }